import { ReactNode } from "react";
import { ConnectionProvider } from "@solana/wallet-adapter-react";

require("@solana/wallet-adapter-react-ui/styles.css");

export function Wallet({ children }: { children: ReactNode }) {
  return (
    <ConnectionProvider endpoint={"https://api.devnet.solana.com"}>
      {children}
    </ConnectionProvider>
  );
}
