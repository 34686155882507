import { Wallet } from "./Wallet";

import { CoinflowPurchase, CoinflowWithdraw } from "@coinflowlabs/react";
import { useConnection } from "@solana/wallet-adapter-react";
import { PublicKey } from "@solana/web3.js";
import { useQueryParam } from "./useQueryParam";
import { Route, Routes, HashRouter } from "react-router-dom";

function App() {
  return (
    <Wallet>
      <div className="App">
        <HashRouter>
          <div
            style={{
              height: "100vh",
            }}
          >
            <Routes>
              <Route path="/" element={<CoinflowContent />} />
              <Route path="/withdraw" element={<CoinflowCheckout />} />
            </Routes>
          </div>
        </HashRouter>
      </div>
    </Wallet>
  );
}

function CoinflowContent() {
  const { connection } = useConnection();
  const [amount] = useQueryParam<number>("amount", 0);
  const [pk] = useQueryParam<string>("pk", "");
  const [redirect] = useQueryParam<string>("redirect", "");

  if (!pk) {
    return <div>Missing pk</div>;
  }

  return (
    <CoinflowPurchase
      wallet={
        {
          publicKey: new PublicKey(pk),
        } as any
      }
      merchantId={process.env.REACT_APP_MERCHANT_ID as string}
      env={"sandbox"}
      connection={connection}
      onSuccess={() => {
        console.log("Purchase Success");
        if (redirect) {
          window.location.replace(redirect);
        }
      }}
      blockchain={"solana"}
      amount={amount}
    />
  );
}

function CoinflowCheckout() {
  const { connection } = useConnection();
  const [pk] = useQueryParam<string>("pk", "");
  const [redirect] = useQueryParam<string>("redirect", "");

  if (!pk) {
    return <div>Missing pk</div>;
  }

  return (
    <CoinflowWithdraw
      wallet={
        {
          publicKey: new PublicKey(pk),
        } as any
      }
      merchantId={process.env.REACT_APP_MERCHANT_ID as string}
      env={"sandbox"}
      connection={connection}
      onSuccess={() => {
        console.log("Withdraw Success");
        if (redirect) {
          window.location.replace(redirect);
        }
      }}
      blockchain={"solana"}
    />
  );
}

export default App;
